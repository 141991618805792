<template>
  <header class="navPage">
    <div class="top-box">
      <!-- <div class="flex">
				<div style="width:15vw;height:5.5vw;"><img src="@/static/logos.jpg" style="width:100%;height:100%;cursor:pointer;"
						@click.stop="jumps('index', 0)" /></div>
				<div class="title-box">
					<strong>美好航程与你开启</strong>
					<div style="display: flex;">
						<a>船员求职</a>&nbsp;<a>船员招聘</a>
					</div>
				</div>
			</div>
			<div class="right-box">海交所挂牌代码150278</div> -->
    </div>
    <div class="nav">
      <!-- <div>
          <img src="/static/logo.png" alt="" />
        </div> -->
      <div class="navItem">
        <div class="nav-ul">
          <a
            :class="{ 'nav-active': index == 0 }"
            @click.stop="jumps('index', 0)"
            href="/index.html"
          >
            首页
          </a>
          <a
            :class="{ 'nav-active': index == 2 }"
            @click.stop="jumps('the_crew_recruitment', 2)"
            href="/the_crew_recruitment.html"
            >船员招聘</a
          >
          <a
            :class="{ 'nav-active': index == 1 }"
            @click.stop="jumps('job_wanted', 1)"
            href="/job_wanted.html"
            >船员求职</a
          >
          <a
            :class="{ 'nav-active': index == 6 }"
            @click.stop="jumps('member_notice', 6)"
            href="/member_notice.html"
          >
            企业公告</a
          >
          <a
            :class="{ 'nav-active': index == 7 }"
            @click.stop="jumps('enterprise_online', 7)"
            href="/enterprise_online.html"
            >企业在线</a
          >
          <a
            :class="{ 'nav-active': index == 3 }"
            @click.stop="jumps('guarantee', 3)"
            href="/guarantee.html"
            >中介担保
          </a>
          <a
            v-if="isComPc"
            :class="{ 'nav-active': index == 4 }"
            @click.stop="jumps('train', 4)"
            href="/train.html"
            >培训班期</a
          >
          <a
            v-else
            :class="{ 'nav-active': index == 4 }"
            @click.stop="jumps('member_notice?id=4', 4)"
            href="/member_notice.html?id=4"
            >培训班期</a
          >
          <a
            :class="{ 'nav-active': index == 8 }"
            @click.stop="jumps('data_download', 8)"
            href="/data_download.html"
          >
            资料下载</a
          >
          <a
            :class="{ 'nav-active': index == 5 }"
            @click.stop="jumps('certificate_handling', 5)"
            href="/certificate_handling.html"
            v-if="isComPc"
            >船员办证</a
          >
          <a
            :class="{ 'nav-active': index == 9 }"
            @click.stop="jumps('shipping_news', 9)"
            href="/shipping_news.html"
          >
            航运新闻</a
          >
          <span
            style="padding: 0 10px"
            :class="{ 'nav-active': index == 10 }"
            @click.stop="showQrCode(10)"
            >考试刷题</span
          >
        </div>
        <a href="login.html" class="login-btn">登录/注册</a>
        <!--  data_download -->
      </div>
      <div
        class="shuati-image-box"
        v-if="centerDialogVisible"
        @click="centerDialogVisible = false"
      >
        <img class="shuati-image" src="@/static/shuati.jpg" alt="" />
      </div>

      <!-- <el-dialog title="提示" :visible.sync="centerDialogVisible" width="30%" center>
        <span slot="footer" class="dialog-footer">
          <el-button @click="centerDialogVisible = false">取 消</el-button>
        </span>
      </el-dialog> -->
    </div>
    <!-- 企业头部管理 -->
    <div class="top-nav" v-if="vuex_token && vuex_token.length > 3">
      <span class="name red">{{ vuex_user.name || vuex_user.simpleName }} </span>
      <span class="phone">{{ vuex_user.phone }} </span>
      <span class="type">会员类型：<span class="red">企业</span> </span>
      <span class="map" @click="enter('4-1')">发布招聘</span>
      <span class="map" @click="enter('4-2')">管理招聘</span>
      <span class="map" @click="enter('4-3')">收到的简历投递</span>
      <span class="map" @click="enter('7')">发布公告</span>
      <span class="map" @click="enter('1')">进入企业中心</span>
      <span class="quit" @click="quit">退出</span>
    </div>
    <!-- 用户头部管理 -->
    <div class="top-nav" v-if="member_token && member_token.length > 3">
      <span class="name red">{{ vuex_user.nickName || vuex_user.simpleName }} </span>
      <span class="phone">{{ vuex_user.phone }} </span>
      <span class="type">会员类型：<span class="red">用户</span> </span>
      <span class="map" @click="enter('1')">账号管理</span>
      <span class="map" @click="enter('3-1')">简历管理</span>
      <span class="map" @click="enter('3-2')">投递记录</span>
      <span class="map" @click="enter('3-3')">收藏企业</span>
      <span class="map" @click="enter('9')">面试通知</span>
      <span class="quit" @click="quit">退出 </span>
    </div>
    <div class="main"></div>
  </header>
</template>

<script>
import BaseMixin from "@/mixins/BaseMixin.vue";
const config = require("@/config/config");
export default {
  mixins: [BaseMixin],
  data() {
    return {
      centerDialogVisible: false,
      dialogVisible: false,
      index: 0,
      pwd: "",
      isComPc: config.isCom,
    };
  },
  watch: {
    // tabIndex(val, old) {
    // 	this.index = val;
    // 	this.$u.vuex('tabIndex', val);
    // }
  },
  mounted() {
    try {
      this.index = JSON.parse(localStorage.getItem("tabIndex"));
    } catch {
      this.index = 0;
    }
    console.log("本地存储tabIndex", parseInt(localStorage.getItem("tabIndex")));
  },
  methods: {
    enter(index) {
      // 企业
      if (this.vuex_token) {
        if (index == "1") {
          this.jump("enterprise_index");
        } else if (index == "2") {
          this.jump("ec_account_manage");
        } else if (index == "3") {
          this.jump("ec_buy_package");
        } else if (index == "4-1") {
          this.jump("post_recruitment_info");
        } else if (index == "4-2") {
          this.jump("manage_recruitment_info");
        } else if (index == "4-3") {
          this.jump("resume_delivery_received");
        } else if (index == "4-4") {
          this.jump("collected_resume");
        } else if (index == "4-5") {
          this.jump("companyResume");
        } else if (index == "5-1") {
          this.jump("ec_training_info");
        } else if (index == "5") {
          this.jump("ec_training_order");
        } else if (index == "5-3") {
          this.jump("training_school");
        } else if (index == "7") {
          this.jump("corporate_announcements");
        } else if (index == "8") {
          this.jump("ec_mediation_guarantee");
        } else if (index == "10") {
          this.showSub = true;
        } else if (index == "11") {
          this.jump("dispatch_crew");
        } else if (index == "12") {
          this.jump("release_schedule");
        }
        if (index != "10") {
          this.checkIndex = index;
          this.$u.vuex("qyCheckIndex", this.checkIndex);
        }
      }
      if (this.member_token) {
        if (index == "1") {
          this.jump("account_manage");
        } else if (index == "2") {
          this.jump("buy_package");
        } else if (index == "3-1") {
          this.jump("resume_manage");
        } else if (index == "3-2") {
          this.jump("delivery_record");
        } else if (index == "3-3") {
          this.jump("collection_position");
        } else if (index == "4") {
          this.jump("personal_guarantee");
        } else if (index == "5") {
          this.jump("training_order");
        } else if (index == "6") {
          this.jump("document_handling");
        } else if (index == "7") {
          // this.jump('my_subscription')
          this.showSub = true;
        } else if (index == "8-1") {
          this.jump("qy_job_browse");
        } else if (index == "9") {
          this.jump("qy_interview_notice");
        }
        if (index != "7") {
          this.checkIndex = index;
          this.$u.vuex("checkIndex", this.checkIndex);
        }
      }
    },
    quit() {
      this.$dialog.confirm({ title: "温馨提示", message: "是否退出账号" }).then(() => {
        this.fedLogOut();
      });
    },
    showQrCode(index) {
      // this.$u.vuex("tabIndex", index);
      this.centerDialogVisible = !this.centerDialogVisible;
    },
    getCurrentDate() {
      var myDate = new Date();
      var year = myDate.getFullYear(); //年
      var month = myDate.getMonth() + 1; //月
      var day = myDate.getDate(); //日
      var days = myDate.getDay();
      switch (days) {
        case 1:
          days = "星期一";
          break;
        case 2:
          days = "星期二";
          break;
        case 3:
          days = "星期三";
          break;
        case 4:
          days = "星期四";
          break;
        case 5:
          days = "星期五";
          break;
        case 6:
          days = "星期六";
          break;
        case 0:
          days = "星期日";
          break;
      }
      var str = year + "年" + month + "月" + day + "日  " + days;
      return str;
    },
    jumps(url, index) {
      // // this.initData()
      // const that = this;
      // console.log(url, index);
      // that.index = index;
      // that.$u.route({
      // 	url: url,
      // 	type: 'redirectTo'
      // });
      // return false;
      console.log("点击tab", index);
      this.$u.vuex("tabIndex", index);
      this.jump(url);
    },
    jumpCenter() {
      this.index = 1000;
      if (this.member_token) {
        this.$u.route({
          url: "account_manage",
          type: "redirectTo",
        });
      } else {
        this.$u.route({
          url: "enterprise_index",
          type: "redirectTo",
        });
      }
    },
    tuichu() {},
  },
};
</script>
<style>
page {
  background: none;
}
</style>
<style lang="less" scoped>
.top-nav {
  padding: 10px 10px;
  background: #e3f0ff;
  border: 1px solid #fff;
  font-size: 14px;
  z-index: 100;
  // display: flex;
  // justify-content: space-around;
  & > span {
    margin: 0 10px;
  }
  .red {
    color: red;
    font-weight: 700;
  }
  .map {
    color: rgb(177, 107, 1);
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
  .quit {
    cursor: pointer;
    &:hover {
      text-decoration: underline;
    }
  }
}
.header-button {
  width: 120px;
  height: 30px;
  line-height: 30px;
  font-size: 12px;
  text-align: center;
  background-repeat: no-repeat;
  background-image: url("@/static/bg2005_11.gif");
  font-weight: bold;
  color: #0072e3;
  cursor: pointer;
}
.shuati-image-box {
  background: rgba(49, 49, 49, 0.6);
  overflow: hidden;
  position: fixed;
  top: 0;
  left: -15.75vw;
  width: 100vw;
  height: 100vh;
  .shuati-image {
    position: fixed;
    top: 50%;
    left: 50%;
    width: 300px !important;
    height: 300px !important;
    transform: translate(-50%, 220px);
  }
}

.navPage {
  width: 68.5vw;
  // margin: 0  auto; width:68.5vw;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translate(-50%);
  // width: 68.6vw;
  background: #fff;
  z-index: 2000;
  border-bottom: 1px solid rgba(32, 137, 255, 0.3) !important;
  background-image: url("@/static/topbod.gif");
  background-size: 100% 100%;
  // box-shadow: 0px 8px 13px 0px rgba(6, 27, 51, 0.13);

  .top-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 9.9vw;
    // padding-right: 1vw;
    color: #0168b7;
    background-image: url("@/static/topWindow.jpg");
    background-size: 100% 100%;
    background-repeat: no-repeat;

    .title-box {
      display: flex;
      flex-direction: column;
      align-items: start;
      letter-spacing: 0.1vw;
      justify-content: center;
      font-size: 1.2vw;
      line-height: 2.25vw;
    }

    .right-box {
      font-size: 0.8vw;
      line-height: 2.25vw;
      letter-spacing: 0.1vw;
    }
  }

  .nav {
    // padding:0.78vw 2vw;
    height: 3vw;
    line-height: 3vw;
    background: linear-gradient(0deg, #aadaff, #0b7ed9);
    color: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 0.72vw;
    box-shadow: 0px 7px 13px 0px rgba(1, 22, 46, 0.04);
    cursor: pointer;

    img {
      width: 3.125vw;
      height: 3.125vw;
    }
  }

  .navItem {
    text-align: center;
    // font-weight: 600;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 2vw;

    .nav-ul {
      display: flex;

      a {
        // height: 100%;
        width: 5vw;
        font-size: 0.8vw;
        cursor: pointer;
        color: #fff;
      }
    }

    .login-btn {
      width: 5vw;
      font-size: 0.8vw;
      color: #fff;
    }
  }

  .nav-active {
    width: 5vw;
    background: #278ee0;
    // color:#2089FF;
    font-weight: 600;
  }

  .nav-right-color {
    margin-right: 20px;
    color: #fff;
  }
}
</style>
